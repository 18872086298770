<template>
  <FocusTrap>
    <div id="mycard">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3">
              <h5 class="card-title">Login Details</h5>
              <p class="text-muted font-weight-light">Details used for authenticating with the application.</p>
            </div>

            <div class="col-md-9">

              <div class="row pt-2">
                <div class="col-md-6">
                  <label for="email">Email</label>
                  <input ref="txtEmail" type="email" class="form-control input-solid" id="email" name="email" placeholder="Email"  maxlength="50" autocomplete="off" required v-model="user.email" autofocus>
                </div>

                <div class="col-md-6">
                  <label for="txtName">Name</label>
                  <input id="txtName" type="text" class="form-control input-solid" placeholder="User Name" autocomplete="off" maxlength="50" required v-model="user.name">
                </div>
              </div>

              <div class="row pt-2">
                <div class="col-md-6">
                  <label for="password">Password</label>
                  <input ref="txtPassword" type="password" class="form-control input-solid" id="password" name="password" required v-model="user.password">
                </div>

                <div class="col-md-6">
                  <label for="password_confirmation">Confirm Password</label>
                  <input ref="txtCPassword" type="password" class="form-control input-solid" id="password_confirmation" name="password_confirmation" required v-model="user.c_password">
                </div>
              </div>

              <div class="row pt-2">
                  <div class="col-md-6">
                    <button class="btn btn-outline-success" @click="create_user" >Create <i class="icon icon-user pl-2"></i> </button>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </FocusTrap>
</template>

<script>
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'

export default {
  name: 'UserForm',
  store,
  data(){
    return {
      user : { "id":0,"name":"", "email":"", "password":"", "c_password":"" }
    }
  },
  methods:{
    create_user() {
      const self = this;
      try {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const isValid = emailPattern.test(email);

        if (self.$data.user.name.toString().length < 5) {
          alert('Invalid Name');
          return
        } else if (self.$data.user.email.length == 0) {
          alert('Invalid Email entered');
          return
        } else if (self.$data.user.password !== self.$data.user.c_password) {
          alert('Password mismatch');
          return
        }

        //mycard
        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        const requestOptions = {
          method: 'post',
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.user)
        }

        // alert(`${process.env.VUE_APP_ROOT_API}api/user/register`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/user/register`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
            swal({
              title: 'Success!', text: resp.msg, type: 'success', onClose: () => {
                $('#txtEmail').focus();
              }, timer: 1500
            });
            $('#txtEmail').focus();
            self.user = JSON.parse('{ "id":0,"name":"", "email":"", "password":"", "c_password":"" }');
            self.$emit('user_saved', resp.data);
          } else {
            swal({
              title: 'Oops!', text: resp.msg, type: 'error', onClose: () => {
                $('#txtEmail').focus()
              }, timer: 3000
            });
          }
        }).catch(function (err) {
          swal({
            title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => {
              $('#txtEmail').focus()
            }, timer: 3000
          });
        }).finally(() => {
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }

    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }
</style>
